@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


* { 
	box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;

}

:root {
  --pri-color- : #1C174B;
  --sec-color- : #FCAF26;
  --acc-color-: #009d5e;
  --pri-font-: 'Montserrat', sans-serif;
}
html, body {
	min-height: 100%;
	color: #2d2d2d;
}
	/* sets the body height of the browser, so that backgrounds and div heights work correctly. Color and background will almost certainly be altered; they are just placeholders */
	
body {
	font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
	text-rendering: optimizeLegibility;
}
	/* sets the base font size for the document; turns on kerning pairs and ligatures */
	
body, ul, ol, dl {
	margin: 0;
}
article, aside, audio, 
footer, header, nav, section, video {
	display: block; 
	}
	
	/* ensures that older browsers will display HTML5 elements correctly */
	
h1 {
	font-size: 1.5rem;
}
	/* begins to set up font sizes relative to base rem â€“ h1 has been used as an example */
	
p { 
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
} 
	/* sets hyphenation by default on paragraphs */
	
textarea { 
	resize: vertical;
}
 /* changes textarea resizing from "both" (UA default) to vertical only */
 
table { border-collapse: collapse; }
td {
	padding: .5rem;
}
	/* causes tables to actually look like tables */
	
img { 
	border: none;
	max-width: 100%;
}
	/* removes outlines from linked images (for older browsers and IE), sets up images for responsive design layout */
	
input[type="submit"]::-moz-focus-inner, 
	input[type="button"]::-moz-focus-inner {
		border : 0px;
	}
	/* removes the inner border effect from focused buttons for form elements in Firefox */
	
input[type="search"] { 
	-webkit-appearance: textfield;
} 
input[type="submit"] { 
	-webkit-appearance:none;
}
	/* removes the OS X appearance from HTML5 search inputs and submit buttons when viewed in Safari */
	
input:required:after {
	color: rgb(255, 255, 255);
	content: " *";
}
	/* sets up required form fields with the conventional following red asterix */
input[type="email"]:invalid { 
	background: rgb(254, 254, 254);
}
	/* causes a visual alert in browsers that do client-side checking of HTML5 email field â€“ this may already be the default in some browsers. */
	
.right { 
	float: right;
	margin-left: 2rem;
	clear: right;
}
.left { 
	float: left;
	margin-right: 2rem;
	clear: left;
}
	/* allows a quick shortcut for floating elements left and right while avoiding the â€œfloat quirkâ€ problem */
	
sub, sup { 
	line-height: 0;
}
/* ///////////nav////////////// */
.container-fluid {
    max-width: 100%;
}

/* navbar */
nav {
  background-color: #f5fbff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.topnav {
  overflow: hidden;
}

.link-logo {
  text-decoration: none;
  margin-right: auto;
}

.link-item {
  color: #1C174B;
  text-align: center;
  margin: 0 10px;
  text-decoration: none;
  font-size: 0.9rem;
}

.link-item:hover {
  border-bottom: 2px solid #FCAF26;
}

.link-icon {
  display: none;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  background-color: #FCAF26;
  text-decoration: none;
}

.fa-bars {
  text-decoration: none;
}

@media screen and (max-width: 760px) {
  .link-item{
    display: none;
  }

  .link-icon {
    align-self: flex-start;
    display: block;
  }
}

@media screen and (max-width: 760px) {
  .responsive {
    position: relative;
  }

  .responsive .link-icon {
    position: absolute;
    right: 0;
    top: 0; 
  }
  
  .responsive .link-item {
    float: none;
    display: block;
    text-align: left;
    margin-top: 20px;
  }
}


.redalogo {
  width: 120px;
}


/* header */
header {
  background: rgba(0, 33, 77, 0.91);
  background-image: url('https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1596&q=80');
  /* background: radial-gradient(circle, rgba(23,74,139,1) 0%, rgba(13,17,111,1) 100%); */
  background-blend-mode: overlay;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0; 
  margin-bottom: 40px;
}
  
  header .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .header-event-name {
    color: #fff;
    font-size: 4rem;
    text-align: center;
    margin: 1.5rem 0;
    font-weight: 800;
  }
  
  .header-event-theme {
    color: #fff;
    font-size: 2.5rem;
    text-align: center;
    margin: 1rem 0;
  }

  .header-event-theme::before {
    content: 'Theme:';
    color: #1C174B;
    font-size: 1rem;
    font-family:cursive;
    margin: 0 auto;
    font-weight: 600;
    border-radius: 5px;
    width: max-content;
    padding: 8px 12px;
    background-color: #FCAF26;
    display: block;
  }
  


  .header-event-date,
  .header-event-time,
  .header-event-venue {
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  
  .thinkmint-logos {
    width: 300px;
  }
  

  .header-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 10px;
  }

  /* on mobile */
  @media screen and (max-width: 768px) {
    .header-buttons {
      grid-template-columns: 1fr;
    }
  }
  
  
  .header-button-getaccess {
    width: 100%;
    display: block;
    margin: 5px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    background-color: var(--acc-color-);
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px;
    border-radius: 6px;
  
  }
  
  .header-button-register {
    width: 100%;
    display: block;
    margin: 5px;
    text-decoration: none;
    text-align: center;
    color: var(--pri-color-);
    background-color: var(--sec-color-);
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px;
    border-radius: 6px;
  }

  .header-button-masterclass {
    width: 100%;
    display: block;
    margin: 5px;
    text-decoration: none;
    text-align: center;
    color:#fff;
    background-color:#1c5bda;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px;
    border-radius: 6px;
  }

  .header-button-visit {
    width: 100%;
    display: block;
    margin: 5px;
    text-decoration: none;
    text-align: center;
    color: var(--pri-color-);
    background-color:#e2f4ff;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px;
    border-radius: 6px;
  }

  .header-button-brochure {
    width: 100%;
    display: block;
    margin: 5px;
    text-decoration: none;
    text-align: center;
    color: var(--pri-color-);
    background-color:#7dc4fd;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px;
    border-radius: 6px;
  }

  .reps-visit {
    width: 100%;
    display: block;
    margin: 5px;
    text-decoration: none;
    text-align: center;
    color: maroon;
    background-color:#fff;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 12px;
    border-radius: 6px;
  }


  .videoWrapper {
    text-align: center;
    }

  .logo-section-title {
    color: var(--pri-color-);
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    margin: 20px 0 ;
  }

  .orgainising-logo {
    text-align: center;
    margin-bottom: 30px;
  }



  .logo-item {
    width: 80px;
    margin: 0 10px;
  }
  
  @media screen and (max-width: 760px){
  iframe {
    width: 100%;
  }  
  }

  /* theme */
  .subtheme-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr) ;
    gap: 10px;
  }
  
  @media screen and (max-width: 780px) {
    .subtheme-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr) ;
      gap: 10px;
    }
  }
  
  .subtheme-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center ;
  }
  
  .subtheme-icon {
    width: 80px;
  }
  
  .subtheme-title {
    font-size: 0.8rem;
    text-transform: capitalize;
    margin-top: 10px;
    color: var(--pri-color-);
    text-align: center;
  }

#highlight2023 {
  background-color: #FCAF26;
}

/* speakers */
.speaker-bg {
  background-color: #f5fbff;
}

.speaker-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

#speakers {
  /* background-color: #f3f3f3; */
  margin-top: 30px;
}

.speaker-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  height: 100%;
}

.speaker-link:hover {
  background-color: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  cursor: pointer;
}

.speaker-title {
  color: var(--sec-color-);
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}

.speaker-position {
  color:#4f4f4f;
  font-size: 0.8rem;
  text-align: center;
  margin: 0;
}

.speaker-company {
  color:#8b8b8b;
  font-size: 0.8rem;
  text-align: center;
  margin: 0;
  
}

@media screen and (max-width:760px) {
  .speaker-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .speaker-title {
    font-size: 1rem;
  }

  .speaker-position {
    font-size: 0.6rem;

  }
}
  /* topic list */
  .section-title {
    color: var(--pri-color-);
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .mycontainer {
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 40px;
  }


  .agenda-bg {
    background-color: #e2f4ff;
    padding: 20px 0;
  }

  .table-title {
    font-weight: 600;
    font-size: 0.8rem;
  }

  .table-content {
    font-weight: 300;
    font-size: 0.8rem;
  }

  .table-time {
    font-size: 0.8rem;
  }



  .topic-group {
    display: flex;
    flex-direction: column;
  }

  .topic-item {
    background-color: #e2f4ff;
    color: var(--pri-color-);
    margin: 10px auto;
    padding: 10px;
    font-size: 0.8rem;
    font-weight: 600;
  }


  /* tickets */

 .ticket-section {
    background-color: #dcdcdc;
    padding: 40px 0;
  }


  .ticket-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    gap: 10px;
  }
  
  @media screen and (max-width:780px) {
    .ticket-group {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
  
  .ticket-item {
    width: 100%;
    background-color: #e2f4ff;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    
  }

  .ticket-head {
    background-color:var(--pri-color-);
    padding: 12px;
    margin-bottom: 10px;
    height: 120px;
    position: relative;
  
  }
  
  .ticket-head h3{
    color: var(--sec-color-);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .ticket-body {
    padding: 12px;
    position: relative;
  }

  .ticket-body p {
    font-size: 0.8rem;
    margin: 8px 0;
  }
  
  .regular::before {
    content: 'Regular';
    display: block;
    font-size: 1.2rem;
    color: #fff; 
  }
  
  .standard::before {
    content: 'Standard';
    display: block;
    font-size: 1.2rem;
    color: #fff; 
  }
  .vip::before {
    content: 'VIP';
    display: block;
    font-size: 1.2rem;
    color: #fff; 
  }
  .six::before {
    content: 'Table for 6';
    display: block;
    font-size: 1.2rem;
    color: #fff; 
  }

  .ticket-button {
    margin: 12px;
  }
  
  .ticket-button a {
    display: block;
    text-decoration: none;
    background-color: #009d5e;
    color: #ffff;
    padding: 12px;
    font-size: 0.8rem;
    border-radius: 6px;
    text-align: center;
  }
  
/* form section */
.form-section {
  padding: 40px 0;
  background-color: #1C174B;
}
  
  input {
    margin-bottom: 10px;
    padding: 8px 12px;
    border: 1px solid #868686;
    border-radius: 6px;
    display: block;
    width: 100%;

  }

  .form-submit {
    display: block;
    background-color: #FCAF26;
    color: #1C174B;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    padding: 8px 24px;
    margin-top: 20px;
    width: max-content;
  }

    .form-submit:hover {
    background-color: #009d5e;
    color: #fff;
  }

  .form-input-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 10px;
  }

  /* on mobile */
  @media screen and (max-width: 760px) {
    .form-input-group {
      grid-template-columns: 1fr;
    }
  }

  .success-message {
    background-color: rgb(0, 160, 104);
    margin-top: 20px;
    border-radius: 6px;
    padding: 10px;
    color: #fff;
    width:max-content;
    text-align: center;
    font-size: 0.8rem;
    }
  
  .error-message {
    background-color: crimson;
    padding: 10px;
    color: #fff;
    width:max-content;
    }
  
  #form-response {
    text-align: center;
    display: block;
    margin: 10px auto;    
    }
  
    
 

  /* form end */
  
  footer {
    background-color: var(--pri-color-);
    padding: 40px 0;
  }
  
  .footer-title {
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  
  .footer-contact {
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }
  
  :is(.footer-phone, .footer-email){
    font-size: 1rem;
    color:#FCAF26;
    font-weight: 600;
    margin: 0;
  }
  
  
  .footer-base {
    color: #7dc4fd;
    text-align: center;
    font-size: 0.8rem;
  }

  .error-msg {
    text-align: center;
    color: orangered;
    font-size: 14px;
    font-weight: bold;
  }

  .about-title {
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    color: #FCAF26;
    margin-bottom: 10px;
  }

  .about-position {
    text-align: center;
    margin-bottom: 30px;
  }

  .about-text {
    line-height: 1.8;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }


  .marketplace-group {
    background-color: #e2f4ff;
    margin: 40px 0;
    text-align: center;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .marketplace-title {
    font-size: 1.2rem;
    font-weight: 700;
    color: maroon;
  }

  .marketplace-date {
    font-size: 1rem;
    font-weight: 300; 
  }

  .marketplace-map {
    font-size: 1rem;
    font-weight: 300;
  }

  .marketplace-logo {
    margin-bottom: 8px;
  }

  .marketplace-logo-img {
    width: 50px;
    margin: 0 20px;
  }

  .marketplace-button {
    background-color: maroon;
    color: #fff;
    display: block;
    margin: 8px auto;
    text-decoration: none;
    text-align: center;
    width: max-content;
    padding: 8px 12px;
    border-radius: 5px;
  }

  .vote-banner {
    padding: 40px 0;
    display: block;
    margin: 0 auto;
    text-align: center;
  }